import {useEffect, useRef} from "react";
import getOriginLocation from "utils/googleMap/getOriginLocation";
import {mapId} from "utils/googleMap/googleMapSettings";

interface Props {
    address: string | null
}

const Map = ({address}: Props) => {
    const ref = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (ref.current) {
            const map = new window.google.maps.Map(ref.current, {
                center: {lat: 39.67096338359407, lng: -101.5601656163939},
                zoom: 3,
                fullscreenControl: false,
                disableDefaultUI: true,
                clickableIcons: false,
                mapId: mapId,
                zoomControl: false,
                draggable: false,
                backgroundColor: '#0000002b'
            });

            if (address) {
                getOriginLocation(address, map)
            }

        }
    }, [ref, address]);

    return (
        <div
            ref={ref}
            className={'w-full h-[208px]'}
        />
    );
}

export default Map
