import {Checkbox} from "cn/components/ui/checkbox";
import {Input} from "cn/components/ui/input";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "cn/components/ui/table";
import OrdersTableSkeleton from "cn/custom/skeletons/OrdersTableSkeleton";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import useSWR from "swr";
import fetcher from "utils/API/fetcher";
import {ApiHost} from "utils/config/config";
import {handleResponseError} from "utils/errorHandler/fetchErrors/handleResponseError";
import { OrderPreset } from "utils/types/primary/OrderPreset";
import usePresets from "utils/zustand/tables/usePresets";

export default function Companies() {
    const navigate = useNavigate();

    const {
        presetList,
        setPresetList,
        searchBar,
        setSearchBar,
        isAnySelected,
        checkAllRows,
        currentSelectedRows,
        checkRow,
        isRowChecked
    } = usePresets()

    const {
        data: orderPresetSource,
        isLoading,
        error,
    } = useSWR<OrderPreset[]>(ApiHost + encodeURI(`api/v1/preset/`), fetcher);

    useEffect(() => {
        if (error) {
            handleResponseError(error, () => navigate('/'))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    useEffect(() => {
        if (!error && orderPresetSource) {
                setPresetList(orderPresetSource);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderPresetSource]);

    return <div className={'flex flex-col gap-4 h-full'}>
        <h1 className={'flex-none text-2xl font-semibold text-marcoDefaultTextColor font-inter'}>Presets</h1>

        <section className={'flex-none text-sm flex justify-between place-items-center'}>
            <div className={'flex grow gap-4 place-items-center'}>
                <Input
                    className={"px-3 py-2 w-[17.25rem] h-8 relative bg-[#ffffff] rounded-lg border border-solid font-subtle font-[number:var(--subtle-font-weight)] text-[#717171] text-[length:var(--subtle-font-size)] tracking-[var(--subtle-letter-spacing)] leading-[var(--subtle-line-height)] whitespace-nowrap [font-style:var(--subtle-font-style)] caret-black"}
                    defaultValue={searchBar}
                    placeholder={`Search preset...`}
                    onChange={
                        (event) => {
                            setSearchBar(event.currentTarget.value)
                        }
                    }/>
            </div>
        </section>

        <div className={'flex-1 grow'}>
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableHead className="w-11">
                            <Checkbox checked={isAnySelected()}
                                      onCheckedChange={() => checkAllRows(presetList)}/>
                        </TableHead>
                        <TableHead className={'w-80'}>Name</TableHead>
                        <TableHead className={'w-80'}>Preset type</TableHead>
                        <TableHead className={'w-80'}>Items</TableHead>
                        <TableHead className={'w-80'}>Volume</TableHead>


                    </TableRow>
                </TableHeader>
                <TableBody className={`${(isLoading || orderPresetSource?.length === 0) && 'h-[31.3rem]'}`}>
                    {!isLoading
                        ? presetList.map((preset) => (
                            <TableRow
                                key={preset.name}
                            >
                                <TableCell>
                                    <Checkbox checked={isRowChecked(preset)}
                                              onCheckedChange={() => checkRow(preset)}
                                              onClick={(e) => {
                                                  e.stopPropagation()
                                              }}
                                    />
                                </TableCell>

                                <TableCell>{preset.name}</TableCell>
                                <TableCell>{preset.presetType}</TableCell>
                                <TableCell>{preset.items.length}</TableCell>
                                <TableCell>asdf</TableCell>

                            </TableRow>
                        ))
                        : <OrdersTableSkeleton pageSize={Number(presetList.length)} tableRowsCount={4}/>}
                </TableBody>
            </Table>
        </div>

        {!isLoading && <section className={'flex-none flex justify-between'}>
            <p className={'text-sm text-marcoGrayTextColor'}> {currentSelectedRows.length} of {presetList.length} selected</p>
        </section>}
    </div>
}