import {Checkbox} from "cn/components/ui/checkbox";
import {TableCell, TableRow} from "cn/components/ui/table";
import {useEffect, useState} from "react";

interface Props {
    pageSize: number
    tableRowsCount: number
    checkBox?: boolean
}

const skeletonElement = <p className={'w-full tableSkeletonCell'}/>

type Cell = {
    id: number | string
}

export default function OrdersTableSkeleton({pageSize, tableRowsCount, checkBox}: Props) {

    const [cells, setCells] = useState<JSX.Element[]>([])

    useEffect(() => {
        const rowsArr: Cell[] = [];
        const columnsArr: Cell[] = []

        for (let i = 0; i < pageSize; i++) {
            rowsArr.push({id: i + 'a'})
        }

        for (let i = 0; i < tableRowsCount; i++) {
            columnsArr.push({id: i + 'b'})
        }

        const table = rowsArr.map(el => <TableRow key={el.id} className={'animate-pulse'}>
            {!checkBox && <TableCell className={'opacity-20'}><Checkbox disabled/></TableCell>}
            {columnsArr.map(el => <TableCell key={el.id}>{skeletonElement}</TableCell>).slice(1)}
            <TableCell>{skeletonElement}</TableCell>
        </TableRow>)

        setCells(table)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize, tableRowsCount]);

    return <>
        {cells}
    </>
}