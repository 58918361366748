import {Separator} from "cn/components/ui/separator";
import {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import useSWR from "swr";
import fetcher from "utils/API/fetcher";
import {ApiHost} from "utils/config/config";
import {handleResponseError} from "utils/errorHandler/fetchErrors/handleResponseError";

import {CustomItem} from "utils/types/primary/CustomItem";
import {Order} from "utils/types/primary/Order";
import {OrderItem} from "utils/types/primary/OrderItem";
import useSheet from "utils/zustand/useSheet";
import {ReactComponent as Dots} from "../../../../../icons/threeDotsInLine.svg";
import InventoryCover from "../../../../../cn/custom/sheet/sheetContent/editOrder/editInventory/inventoryCover";

interface Props {
    order: Order | undefined,
}


export default function Inventory({order}: Props) {
    const {id} = useParams();
    const navigate = useNavigate();
    const {open} = useSheet();

    const {
        data: orderItems,
        error: itemsError,
    } = useSWR<OrderItem[]>(id ? ApiHost + encodeURI(`api/v1/${id}/items`) : null, fetcher);

    const {
        data: customItems,
        error: customItemsError,
    } = useSWR<CustomItem[]>(id ? ApiHost + encodeURI(`api/v1/${id}/customItems`) : null, fetcher);

    useEffect(() => {
        if (itemsError) {
            handleResponseError(itemsError, () => navigate('/'))
        }

        if (customItemsError) {
            handleResponseError(customItemsError, () => navigate('/'))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemsError, customItemsError]);

    return <div
        key={'order-inventory'}
        className={'border rounded-lg'}>
        <div className={'flex justify-between p-2 rounded-t-lg place-items-center'}>
            <h3 className={'text-sm font-semibold '}>Inventory</h3>
            <div
                onClick={() => order && open(
                    'Edit inventory',
                    <InventoryCover order={order}/>
                )}
                className={'grid place-items-center xDefault'}>
                <Dots/>
            </div>
        </div>

        {((orderItems && orderItems?.length > 0) || (customItems && customItems?.length > 0)) && <>
            <Separator/>

            <ul className={'p-2 flex gap-2'}>
                <li key={'order-items'}
                    className={'rounded-full bg-gray-100 py-2 px-4 text-xs font-semibold'}>{orderItems?.length} items
                </li>
                <li key={'custom-items'}
                    className={'rounded-full bg-gray-100 py-2 px-4 text-xs font-semibold'}>{customItems?.length} custom
                    items
                </li>
            </ul>
        </>}


    </div>
}