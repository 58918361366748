import {Separator} from "cn/components/ui/separator";
import formatEnumValueToString from "utils/stringHandlers/formatEnumValueToString";
import getString, {ElementTag} from "utils/stringHandlers/getString";
import stringToFormatDateYear from "utils/stringHandlers/stringToFormatDateYear";
import {Order} from "utils/types/primary/Order";
import useSheet from "utils/zustand/useSheet";
import {ReactComponent as Dots} from "../../../../../icons/threeDotsInLine.svg";
import EditDateAndTime from "../../../../../cn/custom/sheet/sheetContent/editOrder/editDateAndTime";

interface Props {
    order: Order | undefined
    mutateOrder: () => void
}

export default function DateAndTime({order, mutateOrder}: Props) {
    const {open} = useSheet()

    if (order) {
        return <div
            key={'order-date-time'}
            className={'border rounded-lg'}>

            <div className={'flex justify-between p-2 rounded-t-lg place-items-center'}>
                <h3 className={'text-sm font-semibold '}>Date and time</h3>
                <div
                    onClick={() => open('Edit date', <EditDateAndTime onMutate={mutateOrder} order={order}/>)}
                    className={'grid place-items-center xDefault'}>
                    <Dots/>
                </div>
            </div>

            {(order.appointmentDate || order.appointmentTime) && <>
                <Separator/>

                <div className={'p-2'}>
                    {getString([
                        {element: stringToFormatDateYear(order?.appointmentDate), tag: ElementTag.items},
                        {element: formatEnumValueToString(order?.appointmentTime).toLowerCase(), tag: ElementTag.items},
                    ])}
                </div>
            </>}
        </div>
    } else return null
}