import updateCustomer from "utils/API/updateCustomer";
import {Customer} from "utils/types/primary/Customer";
import {zodResolver} from "@hookform/resolvers/zod";
import {Button} from "cn/components/ui/button";
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from "cn/components/ui/form";
import {Input} from "cn/components/ui/input";
import {toast} from "cn/components/ui/use-toast";
import ButtonLoading from "cn/custom/buttonLoading";
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {handleResponseError} from "utils/errorHandler/fetchErrors/handleResponseError";
import {isInstanceOf} from "utils/errorHandler/fetchErrors/isInstanceOf";
import {ErrorMessage} from "utils/types/primary/errorMessage";
import customerSchema from "utils/zodSchemas/customerSchema";
import useSheet from "utils/zustand/useSheet";
import * as z from "zod";

interface Props {
    customer: Customer
    onMutate: () => Promise<void>
}

export default function CustomerEdit({customer, onMutate}: Props) {
    const navigate = useNavigate()
    const form = useForm<z.infer<typeof customerSchema>>({
        resolver: zodResolver(customerSchema),
        defaultValues: {
            email: "",
            phone: "",
            emergencyFullName: "",
            emergencyPhone: "",
            firstName: "",
            lastName: ""
        }
    });

    useEffect(() => {
        form.setValue("email", customer.email ?? '');
        form.setValue("phone", customer.phone ?? '');
        form.setValue("emergencyFullName", customer.emergencyFullName ?? '');
        form.setValue("emergencyPhone", customer.emergencyPhone ?? '');
        form.setValue("firstName", customer.firstName ?? '');
        form.setValue("lastName", customer.lastName ?? '');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customer]);

    const {close} = useSheet();
    const [isLoading, setLoading] = useState<boolean>(false);

    async function onSubmit(values: z.infer<typeof customerSchema>) {
        setLoading(true);

        const updatedCustomer: Customer = {
            ...customer,
            ...values
        }

        const res = await updateCustomer(updatedCustomer);
        if (isInstanceOf<ErrorMessage>(res, 'message')) {
            handleResponseError(res, () => navigate('/'))
        } else {
            await onMutate();
            toast({
                description: 'Request fetched'
            })
        }

        setLoading(false);
        close();
    }

    return <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className={"flex flex-col gap-4"}>
            <section className={"flex flex-col gap-4"}>
                <FormField
                    control={form.control}
                    name="firstName"
                    render={({field}) => (
                        <FormItem>
                            <FormLabel>
                                First name
                            </FormLabel>
                            <FormControl>
                                <Input
                                    placeholder={"First name"}
                                    {...field}/>
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}
                />

                <FormField
                    control={form.control}
                    name="lastName"
                    render={({field}) => (
                        <FormItem>
                            <FormLabel>
                                Last name
                            </FormLabel>
                            <FormControl>
                                <Input
                                    placeholder={"Last name"}
                                    {...field}/>
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}
                />

                <FormField
                    control={form.control}
                    name="email"
                    render={({field}) => (
                        <FormItem>
                            <FormLabel>
                                Email
                            </FormLabel>
                            <FormControl>
                                <Input
                                    placeholder={"Email"}
                                    {...field}/>
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}
                />

                <FormField
                    control={form.control}
                    name="phone"
                    render={({field}) => (
                        <FormItem>
                            <FormLabel>
                                Phone
                            </FormLabel>
                            <FormControl>
                                <Input
                                    placeholder={"Phone"}
                                    {...field}/>
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}
                />

                <FormField
                    control={form.control}
                    name="emergencyFullName"
                    render={({field}) => (
                        <FormItem>
                            <FormLabel>
                                Emergency name
                            </FormLabel>
                            <FormControl>
                                <Input
                                    placeholder={"Emergency name"}
                                    {...field}/>
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}
                />

                <FormField
                    control={form.control}
                    name="emergencyPhone"
                    render={({field}) => (
                        <FormItem>
                            <FormLabel>
                                Emergency phone
                            </FormLabel>
                            <FormControl>
                                <Input
                                    placeholder={"Emergency phone"}
                                    {...field}/>
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}
                />
            </section>

            <div
                className="items-start justify-between flex w-full gap-4">
                <Button
                    className={'w-full'}
                    disabled={isLoading}
                    type={"reset"}
                    variant={"outline"}
                    onClick={() => close()}>
                    Cancel
                </Button>
                <Button
                    className={'w-full'}
                    disabled={isLoading}
                    variant={'green'}
                    type={"submit"}>{isLoading ? <ButtonLoading/> : 'Save'}</Button>
            </div>
        </form>
    </Form>
}