import {Button} from "cn/components/ui/button";
import {useToast} from "cn/components/ui/use-toast";

import {ArrowBigDownDash, Trash2, X} from "lucide-react";
import useFilesStore from "pages/main/details/orderDetails/sections/media/useFilesStore";
import useScreenViewStore from "pages/main/details/orderDetails/sections/media/useScreenViewStore";
import React from "react";
import useSWRMutation from "swr/mutation";

import {ReactComponent as Next} from "../../../../../../icons/next.svg";
import {ReactComponent as Previous} from "../../../../../../icons/previous.svg";
import fetcher from "utils/API/fetcher";
import {ApiHost} from "utils/config/config";
import {Image} from "utils/types/primary/Image";
import deleteCurrentFile from "./deleteCurrentFile";
import {File} from "../media/useFilesStore"

function download(dataurl: string) {
    const link = document.createElement("a");
    link.href = dataurl;
    link.click();
}

export default function ScreenView() {
    const {files, setFiles} = useFilesStore();
    const {isOpen, currentFile, setOpen, setCurrentFile, orderId, orderSid} = useScreenViewStore();
    const {toast} = useToast()
    const {
        trigger,
    } = useSWRMutation<Image[]>(ApiHost + encodeURI(`api/v1/file/${orderId}/`), fetcher);


    if (!isOpen) {
        return null
    } else return <div
        className={"fixed z-50 flex h-full flex-col bg-[#111111] text-gray-500 w-full"}>

        <section className={"border-b border-[#9F9F9F] border-opacity-25 h-[68px] bg-[#1a1a1a] grid grid-cols-2"}>
            <div className={"grid place-content-start px-[20px] py-[8px] text-neutral-400 text-sm font-medium"}>
                <span className={"text-neutral-200 text-sm font-bold"}>#{orderSid}</span>
                <span>Media {files.findIndex(file => file.id === currentFile.id) + 1} of {files.length}</span>
            </div>

            <div className={"flex gap-[20px] px-[40px] place-content-end items-center"}>
                <div className={"flex items-center w-[20px] h-[20px] text-gray-50 cursor-pointer"} onClick={() => {
                    const currentFileIndex = files.findIndex(file => file.id === currentFile.id);
                    deleteCurrentFile(orderId, currentFile.id)
                        .then(res => {
                            if (res === 200) {
                                toast({
                                    title: "Fetched",
                                    description: "Request fetched",
                                })
                                trigger().then(value => {
                                    if (value) {
                                        const files: File[] = value.map(
                                            value => {
                                                const file: File = {
                                                    id: value.id,
                                                    createOn: value.createOn,
                                                    imageSource: value.url,
                                                    isHovered: false,
                                                    isChecked: false
                                                }
                                                return file
                                            }
                                        )
                                        setFiles(files);
                                        if (files.length > 0) {
                                            if (currentFileIndex >= 0 && currentFileIndex < files.length) {
                                                setCurrentFile(files[currentFileIndex])
                                            } else setCurrentFile(files[0])

                                        } else {
                                            setOpen(false)
                                        }
                                    }
                                })
                            } else {
                                toast({
                                    variant: "destructive",
                                    description: "Request failed",
                                })
                            }
                        })
                }}><Trash2 className={"w-[18px] h-[18px]"}/></div>

                <div className={"flex items-center w-[20px] h-[20px] text-gray-50 cursor-pointer"}
                     onClick={() => download(currentFile.imageSource)}><ArrowBigDownDash
                    className={"w-[19px] h-[19px]"}/>
                </div>

                <div className={"flex items-center w-[20px] h-[20px] text-gray-50 cursor-pointer"}
                     onClick={() => setOpen(false)}>
                    <X className={"w-[18px] h-[18px]"}/>
                </div>
            </div>
        </section>

        <section className={"flex flex-1"}>
            <div
                className={"border-r border-[#9F9F9F] border-opacity-25 flex-1 grid place-items-center"}>

                <div className={"grid gap-4 grid-cols-10 place-items-center w-full max-w-[800px]"}>

                    <div className={"col-start-1 col-end-1 w-fit"}>
                        <Button variant={"ghost"}
                                className={"bg-[#1A1A1A] hover:bg-gray-700 rounded-3xl w-[50px] h-[50px] flex justify-center"}
                                onClick={() => {
                                    const currentFileIndex = files.findIndex(file => file.id === currentFile.id);
                                    if (currentFileIndex - 1 >= 0) {
                                        setCurrentFile(files[currentFileIndex - 1])
                                    } else setCurrentFile(files[files.length - 1])
                                }}>
                            <Previous/>
                        </Button>
                    </div>

                    <div className={"col-start-2 col-end-10"}>

                        <img alt={'img source'} src={currentFile?.imageSource} className={"max-h-[750px]"}/>


                    </div>

                    <div className={"col-start-10 col-end-10 w-fit"}>
                        <Button variant={"ghost"}
                                className={"bg-[#1A1A1A] hover:bg-gray-700 rounded-3xl w-[50px] h-[50px]"}
                                onClick={() => {
                                    const currentFileIndex = files.findIndex(file => file.id === currentFile.id);
                                    if (currentFileIndex + 1 < files.length) {
                                        setCurrentFile(files[currentFileIndex + 1])
                                    } else setCurrentFile(files[0])
                                }}>
                            <Next/>
                        </Button>
                    </div>
                </div>
            </div>

            <section className={"bg-[#1a1a1a] border-r border-[#9F9F9F] border-opacity-25 flex-none w-[292px]"}>
                <div className={"py-[20px] px-[20px] flex flex-col gap-[8px]"}>

                    <span
                        className={"text-neutral-200 text-sm font-semibold leading-tight break-all"}>{currentFile.imageSource}</span>

                    <span className={"text-neutral-400 text-xs font-medium"}>
                    Write a brief description of the file for
                    people with visual impairment or low-bandwidth connections.</span>

                    <span className={"text-indigo-200 text-xs"}>Add alt text</span>
                </div>
            </section>
        </section>
    </div>
}