import {getGeocode, getLatLng} from "use-places-autocomplete";
import {pickup} from "utils/googleMap/googleMapSettings";
import getString, {ElementTag} from "utils/stringHandlers/getString";

const getOriginLocation = async (address: string | null, map: google.maps.Map) => {
    const stringAddress = getString([
        {element: address, tag: ElementTag.address},
    ])

    const geoCode = await getGeocode({address: `${stringAddress}`});
    const originLatLng = await getLatLng(geoCode[0])

    new google.maps.Marker({
        position: originLatLng,
        map,
        icon: pickup
    })

    map.setCenter(originLatLng);
    map.setZoom(6)
}

export default getOriginLocation