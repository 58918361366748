import {Button} from "cn/components/ui/button";
import {toast} from "cn/components/ui/use-toast";
import {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import useSWR from "swr";
import deleteApplication from "utils/API/deleteApplication";
import fetcher from "utils/API/fetcher";
import {ApiHost} from "utils/config/config";
import routes from "utils/config/routes";
import {handleResponseError} from "utils/errorHandler/fetchErrors/handleResponseError";
import {isInstanceOf} from "utils/errorHandler/fetchErrors/isInstanceOf";
import {Application} from "utils/types/primary/Application";
import {ErrorMessage} from "utils/types/primary/errorMessage";
import useApplication from "utils/zustand/primary/useApplication";

export default function ApplicationDetails() {
    const {id} = useParams();
    const navigate = useNavigate();
    const {
        data: applicationSource,
        error
    } = useSWR<Application>(id ? ApiHost + encodeURI(`api/v1/application/${id}`) : null, fetcher);

    const onDeleteHandle= async () => {
        if (id) {
            const res = await deleteApplication(id);

            if (isInstanceOf<ErrorMessage>(res, 'message')) {
                handleResponseError(res, () => navigate('/'))
            } else {
                toast({
                    description: 'Request fetched'
                })
                navigate(-1)
            }
        }
    }

    const onAcceptHandle = () => {
        if (applicationSource) {
            useApplication.getState().setApplication(applicationSource)
            navigate(routes.createCompany)
        }
    }

    useEffect(() => {
        if (error) {
            handleResponseError(error, () => navigate('/'))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    return <div className={'flex flex-col gap-4 middleWidth container text-marcoDefaultTextColor'}>
        <h1 className={'flex-none text-2xl font-semibold text-marcoDefaultTextColor font-inter'}>Application details</h1>

        <section className={'flex flex-col gap-2'}>
            
            <div className={'flex gap-2'}>
                <span>First name:</span>
                <span>{applicationSource?.firstName}</span>
            </div>

            <div className={'flex gap-2'}>
                <span>Last name:</span>
                <span>{applicationSource?.lastName}</span>
            </div>

            <div className={'flex gap-2'}>
                <span>Email:</span>
                <span>{applicationSource?.email}</span>
            </div>

            <div className={'flex gap-2'}>
                <span>Company name:</span>
                <span>{applicationSource?.companyName}</span>
            </div>

            <div className={'flex gap-2'}>
                <span>Phone:</span>
                <span>{applicationSource?.phone}</span>
            </div>

            <div className={'flex gap-2'}>
                <span>Location:</span>
                <span>{applicationSource?.location ?? ''}</span>
            </div>


            <div className={'flex gap-2'}>
                <span>MC:</span>
                <span>{applicationSource?.mc ?? ''}</span>
            </div>

            <div className={'flex gap-2'}>
                <span>DOT:</span>
                <span>{applicationSource?.dot ?? ''}</span>
            </div>

            <div className={'flex gap-2'}>
                <span>Company size:</span>
                <span>{applicationSource?.companySize ?? ''}</span>
            </div>

            <div className={'flex gap-2'}>
                <span>Service area:</span>
                <span>{applicationSource?.serviceArea ?? ''}</span>
            </div>

            <div className={'flex gap-2'}>
                <span>Offered services:</span>
                <span>{applicationSource?.serviceYouOffer ?? ''}</span>
            </div>

        </section>

        <section className={'flex gap-2'}>
            <Button onClick={onAcceptHandle}>Accept</Button>
            <Button variant={'destructive'} onClick={onDeleteHandle}>Decline</Button>
        </section>
    </div>
}