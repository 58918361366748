import {PlusCircledIcon} from "@radix-ui/react-icons";
import {Badge} from "cn/components/ui/badge";
import {Button} from "cn/components/ui/button";
import {Checkbox} from "cn/components/ui/checkbox";
import {Popover, PopoverContent, PopoverTrigger} from "cn/components/ui/popover";
import {ScrollArea} from "cn/components/ui/scroll-area";
import {Separator} from "cn/components/ui/separator";
import {useEffect} from "react";
import {FilterItem} from "utils/types/suibsidiary/filterItem";
import useCategoriesMap from "utils/zustand/primary/useCategoriesMap";
import useItems from "utils/zustand/tables/useItems";

export default function CategoryStatusFilter() {
    const {
        categoryFilter,
        setPageIndex,
        updateTextFilter,
        checkCategoryStatusValue,
        clearCategoryStatusFilter,
        setCategoryFilter
    } = useItems();

    const {categoriesMap} = useCategoriesMap()



    function updateFilter() {
        const categories = useCategoriesMap.getState().categoriesMap
        const filterItems = categories.values().map(el => {
            const filterItem: FilterItem = {
                checked: false,
                filterItemKey: "\"categories\":",
                filterItemValue: `[${el.id}]`,
                filterItemTitle: `${el.name}`,
            }
            return filterItem
        })
        setCategoryFilter(filterItems)
    }


    useEffect(() => {
        updateFilter();
        updateTextFilter();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoriesMap]);

    function onCheckHandle(filterItem: FilterItem) {
        checkCategoryStatusValue(filterItem);
        updateTextFilter();
        setPageIndex(1)
    }

    function onClearFilterHanle() {
        clearCategoryStatusFilter();
        updateTextFilter();
        setPageIndex(1)
    }

    return <Popover>
        <PopoverTrigger asChild>
            <Button
                variant="outline" size="sm" className="h-8 border-dashed">
                <PlusCircledIcon className="mr-2 h-4 w-4"/>
                Categories

                {categoryFilter.filter(el => el.checked).length > 0 && (
                    <>
                        <Separator orientation="vertical" className="mx-2 h-4"/>
                        <Badge
                            variant="secondary"
                            className="rounded-sm px-1 mr-2 font-normal lg:hidden"
                        >
                            {categoryFilter.filter(el => el.checked).length}
                        </Badge>
                        <div className="hidden space-x-1 lg:flex">
                            {categoryFilter.filter(el => el.checked).length > 2 ? (
                                <Badge
                                    variant="secondary"
                                    className="rounded-sm px-1 font-normal"
                                >
                                    {categoryFilter.filter(el => el.checked).length} selected
                                </Badge>
                            ) : (
                                <>
                                    {
                                        categoryFilter
                                            .filter(el => el.checked)
                                            .map(
                                                el => <Badge
                                                    variant="secondary"
                                                    key={el.filterItemValue}
                                                    className="rounded-sm px-1 font-normal"
                                                >
                                                    {el.filterItemTitle}
                                                </Badge>
                                            )
                                    }
                                </>
                            )}
                        </div>
                    </>
                )}

            </Button>
        </PopoverTrigger>
        <PopoverContent className="w-full p-0" align="start">

            <ScrollArea className={'pr-3 h-64'}>
                <ul className={'w-full h-full text-sm font-medium py-2 px-2'}>
                    {categoryFilter.map(item =>
                        <li
                            onClick={e => onCheckHandle(item)}
                            key={item.filterItemValue}
                            className={'py-[0.15rem] px-2 cursor-pointer itemHover'}>
                            <div className={"gap-3 flex items-center"}>
                                <Checkbox
                                    checked={item.checked}
                                    onCheckedChange={e => onCheckHandle(item)}>
                                </Checkbox>
                                {item.filterItemTitle}
                            </div>
                        </li>)}
                </ul>
            </ScrollArea>
            <Separator/>

            {(categoryFilter.filter(el => el.checked).length > 0) &&
                <div onClick={onClearFilterHanle}
                     className={'text-sm font-medium cursor-pointer py-2 px-2 itemHover rounded-none'}>Clear
                    filters</div>}
        </PopoverContent>
    </Popover>
}