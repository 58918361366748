import {Separator} from "cn/components/ui/separator";
import {Order} from "utils/types/primary/Order";
import useSheet from "utils/zustand/useSheet";
import {ReactComponent as Dots} from "../../../../../icons/threeDotsInLine.svg";
import EditNote from "../../../../../cn/custom/sheet/sheetContent/editOrder/editNote";

interface Props {
    order: Order | undefined
    mutateOrder: () => void
}

export default function Notes({order, mutateOrder}: Props) {
    const {open} = useSheet()

    if (order) {
        return <div
            key={'order-notes'}
            className={'border rounded-lg'}>
            <div className={'flex justify-between p-2 rounded-t-lg place-items-center'}>
                <h3 className={'text-sm font-semibold '}>Notes</h3>
                <div
                    onClick={() => open('Edit note', <EditNote onMutate={mutateOrder} order={order}/>)}
                    className={'grid place-items-center xDefault'}>
                    <Dots/>
                </div>
            </div>

            {order.notes && <>
                <Separator/>
                <div className={'p-2 text-marcoDefaultTextColor text-sm'}>{order.notes}</div>
            </>}

        </div>
    } else return null
}