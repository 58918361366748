import {ScrollArea} from "cn/components/ui/scroll-area";
import {Sheet, SheetContent,} from "cn/components/ui/sheet";
import {X} from "lucide-react";
import useSheet from "utils/zustand/useSheet";

export default function SheetWrapper() {

    const {isOpen, close, title, content} = useSheet();

    return <Sheet open={isOpen}>
        <SheetContent className={`py-0 px-0 w-full sm:w-[480px]`} side={'right'}>
            <section className={'px-4 py-4 flex justify-between place-items-center'}>
                <h1 className={'font-semibold'}>{title}</h1>
                <X className={'xDefault'} onClick={() => close()}/>
            </section>

            <ScrollArea className={'h-[calc(100dvh-4rem)] w-full pr-2'}>
                <div className={`pt-1 px-4 pb-4`}>{content}</div>
            </ScrollArea>
        </SheetContent>
    </Sheet>
}