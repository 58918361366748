import {Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList} from "cn/components/ui/command";
import {Input} from "cn/components/ui/input";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "cn/components/ui/table";
import {toast} from "cn/components/ui/use-toast";
import {Trash2} from "lucide-react";
import {TSMap} from "typescript-map";
import {OrderItem} from "utils/types/primary/OrderItem";


interface Props {
    orderItems: OrderItem[]
    setOrderItems: React.Dispatch<React.SetStateAction<OrderItem[]>>

    categories: TSMap<number, string>
    setCategories: React.Dispatch<React.SetStateAction<TSMap<number, string>>>

    deletedItems: string[]
    setDeletedItems: React.Dispatch<React.SetStateAction<string[]>>
}

export default function ManageItems({orderItems, setOrderItems, categories, setDeletedItems}: Props) {

    function getOrderItemName(orderItem: OrderItem) {
        if (categories.has(orderItem.itemId)) {
            const name = categories.get(orderItem.itemId)
            return name
        } else return ''
    }

    function changeItemCount(orderItem: OrderItem, count: string) {
        const updatedItems: OrderItem[] = [];

        orderItems.forEach(el => {
            if (el.itemId === orderItem.itemId) {
                const item: OrderItem = {
                    ...orderItem,
                    count: Number(count)
                }
                updatedItems.push(item)
            } else return updatedItems.push(el)
        })

        setOrderItems(updatedItems)
    }

    function deleteItem(orderItem: OrderItem) {
        const updatedItems = orderItems.filter(el => el.itemId !== orderItem.itemId);
        if (orderItem.id) {
            const itemId = orderItem.id
            setDeletedItems(prevState => [...prevState, itemId])
        }
        setOrderItems(updatedItems)
    }

    function addItem(key: number | undefined) {
        if (key) {
            const originalItems = orderItems.slice();
            let flag: boolean = false;

            originalItems.forEach(el => {
                if (el.itemId === key) {
                    flag = true;
                    toast({
                        variant: 'destructive',
                        description: 'This category has already been added'
                    })

                }
            })

            if (!flag) {
                const newOrderItem: OrderItem = {
                    itemId: key,
                    count: 1,
                    id: null
                }
                originalItems.push(newOrderItem);
                setOrderItems(originalItems);
                toast({
                    description: 'Item added'
                })
            }
        }
    }



    return <section className={'flex flex-col gap-4'}>
        <Command className="rounded-lg border shadow-md">
            <CommandInput
                placeholder="Search item..."/>
            <CommandList className={'h-[7.5rem]'}>
                <CommandEmpty>No results found.</CommandEmpty>
                <CommandGroup>
                    {categories.map((value, key, index) =>
                        <CommandItem className={'text-xs'}>
                            <p onClick={()=> addItem(key)} className={'w-full'}>{value}</p>
                        </CommandItem>)}
                </CommandGroup>
            </CommandList>
        </Command>

        {(orderItems.length > 0) && <Table>
            <TableHeader className={'text-xs'}>
                <TableRow>
                    <TableHead className="w-52">Name</TableHead>
                    <TableHead className="w-16">Quantity</TableHead>
                    <TableHead/>
                </TableRow>
            </TableHeader>

            <TableBody className={'text-xs'}>
                {orderItems.map(el => <TableRow
                    key={el.id}
                >
                    <TableCell>{getOrderItemName(el)}</TableCell>
                    <TableCell><Input className={'w-20'}
                                      min={1}
                                      type={'number'}
                                      onChange={(e) => changeItemCount(el, e.target.value)}
                                      defaultValue={el.count}/></TableCell>
                    <TableCell><Trash2 onClick={() => deleteItem(el)} className={'h-6 xDefault'}/></TableCell>
                </TableRow>)}
            </TableBody>
        </Table>}
    </section>
}