import {Button} from "cn/components/ui/button";
import {Tabs, TabsContent, TabsList, TabsTrigger} from "cn/components/ui/tabs";
import {toast} from "cn/components/ui/use-toast";
import ButtonLoading from "cn/custom/buttonLoading";
import ManageCustomItems from "cn/custom/sheet/sheetContent/editOrder/editInventory/manageCustomItems";
import ManageItems from "cn/custom/sheet/sheetContent/editOrder/editInventory/manageItems";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import useSWR from "swr";
import {TSMap} from "typescript-map";
import deleteCustomItems from "utils/API/deleteCustomItems";
import deleteOrderItems from "utils/API/deleteOrderItems";
import fetcher from "utils/API/fetcher";
import getItems from "utils/API/getItems";
import updateCustomItems from "utils/API/updateCustomItems";
import updateOrderItems from "utils/API/updateOrderItems";
import {ApiHost} from "utils/config/config";
import {handleResponseError} from "utils/errorHandler/fetchErrors/handleResponseError";
import {isInstanceOf} from "utils/errorHandler/fetchErrors/isInstanceOf";
import {CustomItem} from "utils/types/primary/CustomItem";
import {ErrorMessage} from "utils/types/primary/errorMessage";
import {Order} from "utils/types/primary/Order";
import {OrderItem} from "utils/types/primary/OrderItem";
import useSheet from "utils/zustand/useSheet";

interface Props {
    order: Order
}

export default function InventoryCover({order}: Props) {
    const [isLoading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const {close} = useSheet();

    const [orderItems, setOrderItems] = useState<OrderItem[]>([]);
    const [customItems, setCustomItems] = useState<CustomItem[]>([]);

    const [deletedItems, setDeletedItems] = useState<string[]>([]);
    const [deletedCustomItems, setDeletedCustomItems] = useState<string[]>([]);

    const [categories, setCategories] = useState<TSMap<number, string>>(new TSMap());

    const {
        data: orderItemsSource,
        mutate: mutateOrderItems,
        error: itemsError,
    } = useSWR<OrderItem[]>(ApiHost + encodeURI(`api/v1/${order.id}/items`), fetcher);

    const {
        data: customItemsSource,
        mutate: mutateCustomItems,
        error: customItemsError,
    } = useSWR<CustomItem[]>(ApiHost + encodeURI(`api/v1/${order.id}/customItems`), fetcher);

    useEffect(() => {
        if (itemsError) {
            handleResponseError(itemsError, () => navigate('/'))
        }

        if (customItemsError) {
            handleResponseError(customItemsError, () => navigate('/'))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemsError, customItemsError]);


    useEffect(() => {
        getItems().then(value => {
            if (isInstanceOf<ErrorMessage>(value, 'message')) {
                handleResponseError(value, () => navigate('/'))
            } else {
                const itemsMap = new TSMap<number, string>()
                value.content.forEach(el => itemsMap.set(Number(el.id), el.name))
                setCategories(itemsMap)
            }
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order]);


    useEffect(() => {
        if (orderItemsSource) setOrderItems(orderItemsSource)
    }, [orderItemsSource]);


    useEffect(() => {
        if (customItemsSource) setCustomItems(customItemsSource)
    }, [customItemsSource]);

    async function onSubmit() {
        setLoading(true)

        if (deletedItems.length > 0) {
            const resOnDelete = await deleteOrderItems(order, deletedItems);
            if (isInstanceOf<ErrorMessage>(resOnDelete, 'message')) {
                handleResponseError(resOnDelete, () => navigate('/'))
            } else {
                toast({
                    description: 'Items deleted'
                })
            }
        }

        const resOnPost = await updateOrderItems(order, orderItems);
        if (isInstanceOf<ErrorMessage>(resOnPost, 'message')) {
            handleResponseError(resOnPost, () => navigate('/'))
        } else {
            toast({
                description: 'Items updated'
            })
        }

        if (deletedCustomItems.length > 0) {
            const deleteRes = await deleteCustomItems(order, deletedCustomItems)
            if (isInstanceOf<ErrorMessage>(deleteRes, 'message')) {
                handleResponseError(deleteRes, () => navigate('/'))
            }
        }

        const updateRes = await updateCustomItems(order, [...customItems])
        if (isInstanceOf<ErrorMessage>(updateRes, 'message')) {
            handleResponseError(updateRes, () => navigate('/'))
        }

        await mutateOrderItems();
        await mutateCustomItems()

        setLoading(false);
        close();
    }

    return <section className={'flex flex-col gap-4 text-sm font-medium'}>
        <Tabs defaultValue="manage-items" className="w-full">
            <TabsList className={'w-full flex truncate overflow-x-hidden'}>
                <TabsTrigger className={'grow'} value="manage-items">Manage items</TabsTrigger>
                <TabsTrigger className={'grow'} value="manage-custom-items">Manage custom items</TabsTrigger>
            </TabsList>

            <TabsContent value="manage-items">
                <ManageItems deletedItems={deletedItems} setDeletedItems={setDeletedItems} categories={categories}
                             setCategories={setCategories} orderItems={orderItems} setOrderItems={setOrderItems}/>
            </TabsContent>

            <TabsContent value="manage-custom-items">
                <ManageCustomItems onMutateCustomItems={async () => {
                    await mutateCustomItems()
                }} onMutateOrderItems={async () => {
                    await mutateOrderItems()
                }}
                                   order={order} deletedCustomItems={deletedCustomItems}
                                   setDeletedCustomItems={setDeletedCustomItems} customItems={customItems}
                                   setCustomItems={setCustomItems}/>
            </TabsContent>
        </Tabs>

        <div
            className="items-start justify-between flex w-full gap-4">
            <Button
                className={'w-full'}
                disabled={isLoading}
                type={"reset"}
                variant={"outline"}
                onClick={() => close()}>
                Cancel
            </Button>
            <Button
                onClick={onSubmit}
                className={'w-full'}
                disabled={isLoading}
                variant={'green'}
            >{isLoading ? <ButtonLoading/> : 'Save'}</Button>
        </div>

    </section>
}