import {ApiHost} from "utils/config/config";
import getDefinedError from "utils/errorHandler/fetchErrors/getDefinedError";
import otherError from "utils/errorHandler/fetchErrors/otherError";
import {IData} from "utils/types/primary/IData";
import {Item} from "utils/types/primary/Item";
import useTokenStore from "utils/zustand/useTokenStore";

export default async function getCategories() {
    const token = useTokenStore.getState().token

    try {
        const res = await fetch(ApiHost + encodeURI(`api/v1/item?range=[0, 10000]}`), {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                "content-type": "application/json"
            }
        })

        if (res.ok) {
            const data: IData<Item> = await res.json();
            return data
        } else {
            const err = await getDefinedError(res)
            return err
        }
    } catch (e) {
        return otherError
    }
}