import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "cn/components/ui/table";
import OrderStatusBadge from "cn/custom/badges/orderStatusBadge";
import Pagination from "cn/custom/pagination";
import OrdersTableSkeleton from "cn/custom/skeletons/OrdersTableSkeleton";
import NoOrders from "cn/custom/skeletons/noOrders";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom"
import useSWR from "swr";
import fetcher from "utils/API/fetcher";
import {ApiHost} from "utils/config/config";
import routes from "utils/config/routes";
import {handleResponseError} from "utils/errorHandler/fetchErrors/handleResponseError";
import stringToFormatDateYear from "utils/stringHandlers/stringToFormatDateYear";
import {IData} from "utils/types/primary/IData";
import {Order} from "utils/types/primary/Order";

export default function CompanyOrders() {
    const [pageSize, setPageSize] = useState<string>('10');
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [totalOrders, setTotalOrders] = useState<number>(0);
    const {id} = useParams();
    const navigate = useNavigate();

    const {
        data: orderSource,
        error: orderSourceError,
        isLoading: orderSourceLoading
    }
        = useSWR<IData<Order>>(id ? ApiHost + encodeURI(`api/v1/order` +
            `?filter={"carrierCompany":"${id}"}
        &sort=[createOn, DESC]
        &range=[${(pageIndex - 1) * Number(pageSize)},${(pageIndex - 1) * Number(pageSize) + (Number(pageSize) - 1)}]`) : null,
        fetcher);

    useEffect(() => {
        if (orderSourceError) {
            handleResponseError(orderSourceError, () => navigate('/'))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderSourceError]);

    useEffect(() => {
        if (orderSource) {
            setTotalOrders(orderSource.totalElements)
        }
    }, [orderSource]);

    if (orderSource && orderSource.content.length === 0) {
        return <NoOrders content={'This company hasn\'t accepted any orders yet'}/>
    }

    return <div className={'flex flex-col gap-4 text-marcoDefaultTextColor'}>

        {(totalOrders > 0) && <section className={'grid grid-cols-4 border rounded-lg py-2 px-4 text-sm font-medium'}>
            <div className={'flex flex-col border-r'}>
                <p>All time</p>
            </div>

            <div className={'flex flex-col pl-2 border-r'}>
                <p>Total orders</p>
                <p className={'font-bold'}>{totalOrders}</p>
            </div>

            <div className={'flex flex-col pl-2 border-r'}>
                <p>Total payouts</p>
                <p className={'font-bold'}>$TBD</p>
            </div>

            <div className={'flex flex-col pl-2'}>
                <p>Defect rate</p>
            </div>
        </section>}

        <section className={'flex flex-col gap-2'}>
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableHead className="w-36">Order</TableHead>
                        <TableHead className="w-26">Date</TableHead>
                        <TableHead className="w-36">Estimate</TableHead>
                        <TableHead>Order status</TableHead>
                    </TableRow>
                </TableHeader>

                <TableBody className={`${(orderSourceLoading || orderSource?.content.length === 0) && 'h-[31.3rem]'}`}>
                    {!orderSourceLoading
                        ? orderSource?.content.map((order) => (
                            <TableRow
                                key={order.id}
                                className={'cursor-pointer'}
                                onClick={() => navigate(`${routes.orderDetails}/${order.id}`)}
                            >

                                <TableCell
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        navigate(`${routes.orderDetails}/${order.id}`)
                                    }}
                                    className={'tableCellHover'}
                                >#{order.sid}</TableCell>

                                <TableCell>{stringToFormatDateYear(order.appointmentDate)}</TableCell>


                                <TableCell>
                                    {"$" + order.invoices.reduce((sum, current) => sum + current.count, 0).toFixed(2)}
                                </TableCell>

                                <TableCell><OrderStatusBadge status={order.status}/></TableCell>
                            </TableRow>
                        ))
                        : <OrdersTableSkeleton pageSize={Number(pageSize)} tableRowsCount={4} checkBox/>}
                </TableBody>
            </Table>

            <div className={'flex place-content-end'}>
                <Pagination data={orderSource} pageSize={pageSize} pageIndex={pageIndex} setPageSize={setPageSize}
                            setPageIndex={setPageIndex}/>
            </div>
        </section>
    </div>
}