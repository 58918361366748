import {Checkbox} from "cn/components/ui/checkbox";
import {DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger} from "cn/components/ui/dropdown-menu";
import {Input} from "cn/components/ui/input";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "cn/components/ui/table";
import Pagination from "cn/custom/pagination";
import OrdersTableSkeleton from "cn/custom/skeletons/OrdersTableSkeleton";
import {ArrowDownWideNarrow, ArrowUpNarrowWide} from "lucide-react";
import {getCustomerName} from "pages/main/tables/finances/getCustomerName";
import {getOrderSid} from "pages/main/tables/finances/getOrderSid";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import useSWR from "swr";
import fetcher from "utils/API/fetcher";
import {ApiHost} from "utils/config/config";
import routes from "utils/config/routes";
import {handleResponseError} from "utils/errorHandler/fetchErrors/handleResponseError";
import getString, {ElementTag} from "utils/stringHandlers/getString";
import stringToFormatDateYear from "utils/stringHandlers/stringToFormatDateYear";
import {Customer} from "utils/types/primary/Customer";
import {IData} from "utils/types/primary/IData";
import {Order} from "utils/types/primary/Order";
import {PaymentTransaction} from "utils/types/primary/PaymentTransaction";
import useCustomersMap from "utils/zustand/primary/useCustomersMap";
import useOrdersMap from "utils/zustand/primary/useOrdersMap";
import useFinances from "utils/zustand/tables/useFinances";

export default function Finances() {
    const navigate = useNavigate();

    const {
        transactionList,
        setTransactionList,
        pageIndex,
        setPageIndex,
        searchBar,
        setSearchBar,
        sort,
        setSort,
        pageSize,
        setPageSize,
        resetPagination,
        isAnySelected,
        checkAllRows,
        currentSelectedRows,
        checkRow,
        isRowChecked
    } = useFinances()



    const {
        data: transactionSource,
        isLoading,
        error
    }
        = useSWR<IData<PaymentTransaction>>(ApiHost + encodeURI(`api/v1/transaction` +
        `?range=[${(pageIndex - 1) * Number(pageSize)},${(pageIndex - 1) * Number(pageSize) + (Number(pageSize) - 1)}]
        &sort=[${sort}]
       &filter={q:"${searchBar}"}
        `),
        fetcher);

    const {customers, setCustomers} = useCustomersMap();
    const {
        data: customerSource,
        error: customerSourceError,
        isLoading: customerSourceLoading
    }
        = useSWR<IData<Customer>>(customers.length > 0 ? ApiHost + encodeURI(`api/v1/customer?filter={id:[${customers}]}&range=[0, 29]`) : null, fetcher);

    const {orders, setOrders} = useOrdersMap();
    const {
        data: orderSource,
        error: orderSourceError,
        isLoading: orderSourceLoading
    }
        = useSWR<IData<Order>>(orders.length > 0 ? ApiHost + encodeURI(  `api/v1/order?filter={id:[${orders}]}&range=[0, 29]` ): null, fetcher);

    useEffect(() => {
        if (error) {
            handleResponseError(error, () => navigate('/'))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    useEffect(() => {
        if (customerSourceError) {
            handleResponseError(customerSourceError, () => navigate('/'))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerSourceError]);

    useEffect(() => {
        if (orderSourceError) {
            handleResponseError(orderSourceError, () => navigate('/'))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderSourceError]);

    useEffect(() => {
        if (!error && transactionSource) {
            setTransactionList(transactionSource.content);

            const customers = transactionSource.content.map(el => el.userId);
            setCustomers(customers);

            const orders = transactionSource.content.map(el => el.orderId);
            setOrders(orders)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transactionSource]);

    return <div className={'flex flex-col gap-4 h-full'}>
        <h1 className={'flex-none text-2xl font-semibold text-marcoDefaultTextColor font-inter'}>Finances</h1>

        <section className={'flex-none text-sm flex justify-between place-items-center'}>
            <div className={'flex grow gap-4 place-items-center'}>
                <Input
                    className={"px-3 py-2 w-[17.25rem] h-8 relative bg-[#ffffff] rounded-lg border border-solid font-subtle font-[number:var(--subtle-font-weight)] text-[#717171] text-[length:var(--subtle-font-size)] tracking-[var(--subtle-letter-spacing)] leading-[var(--subtle-line-height)] whitespace-nowrap [font-style:var(--subtle-font-style)] caret-black"}
                    defaultValue={searchBar}
                    placeholder={`Search transaction...`}
                    onChange={
                        (event) => {
                            setSearchBar(event.currentTarget.value)
                        }
                    }/>
            </div>
        </section>

        <div className={'flex-1 grow'}>
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableHead className="w-11">
                            <Checkbox checked={isAnySelected()}
                                      onCheckedChange={() => checkAllRows(transactionList)}/>
                        </TableHead>

                        <TableHead className={'tableCellHover w-80'}>
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <p>Customer</p>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent className="w-fit" align={'start'}>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`userId,ASC`);
                                                          resetPagination()
                                                      }}><ArrowUpNarrowWide className={'h-4'}/> <p>Ascending</p>
                                    </DropdownMenuItem>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`userId,DESC`);
                                                          resetPagination()
                                                      }}><ArrowDownWideNarrow
                                        className={'h-4'}/> <p>Descending</p></DropdownMenuItem>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </TableHead>

                        <TableHead className={'w-36'}>Order</TableHead>

                        <TableHead className={'tableCellHover w-60'}>
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <p>Stripe</p>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent className="w-fit" align={'start'}>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`stripeId,ASC`);
                                                          resetPagination()
                                                      }}><ArrowUpNarrowWide className={'h-4'}/> <p>Ascending</p>
                                    </DropdownMenuItem>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`stripeId,DESC`);
                                                          resetPagination()
                                                      }}><ArrowDownWideNarrow
                                        className={'h-4'}/> <p>Descending</p></DropdownMenuItem>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </TableHead>

                        <TableHead className={'tableCellHover w-44'}>
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <p>Amount</p>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent className="w-fit" align={'start'}>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`amount,ASC`);
                                                          resetPagination()
                                                      }}><ArrowUpNarrowWide className={'h-4'}/> <p>Ascending</p>
                                    </DropdownMenuItem>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`amount,DESC`);
                                                          resetPagination()
                                                      }}><ArrowDownWideNarrow
                                        className={'h-4'}/> <p>Descending</p></DropdownMenuItem>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </TableHead>

                        <TableHead className={'tableCellHover w-44'}>
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <p>Status</p>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent className="w-fit" align={'start'}>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`status,ASC`);
                                                          resetPagination()
                                                      }}><ArrowUpNarrowWide className={'h-4'}/> <p>Ascending</p>
                                    </DropdownMenuItem>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`status,DESC`);
                                                          resetPagination()
                                                      }}><ArrowDownWideNarrow
                                        className={'h-4'}/> <p>Descending</p></DropdownMenuItem>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </TableHead>

                        <TableHead>
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <p className={'tableCellHover'}>Create on</p>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent className="w-fit" align={'start'}>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`createOn,ASC`);
                                                          resetPagination()
                                                      }}><ArrowUpNarrowWide className={'h-4'}/> <p>Ascending</p>
                                    </DropdownMenuItem>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`createOn,DESC`);
                                                          resetPagination()
                                                      }}><ArrowDownWideNarrow
                                        className={'h-4'}/> <p>Descending</p></DropdownMenuItem>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </TableHead>

                    </TableRow>
                </TableHeader>
                <TableBody className={`${(isLoading || transactionSource?.content.length === 0) && 'h-[31.3rem]'}`}>
                    {!isLoading
                        ? transactionList.map((transaction) => (
                            <TableRow
                                key={transaction.id}
                            >
                                <TableCell>
                                    <Checkbox checked={isRowChecked(transaction)}
                                              onCheckedChange={() => checkRow(transaction)}
                                    />
                                </TableCell>

                                <TableCell className={'tableCellHover'}
                                           onClick={() => navigate(`${routes.customerDetails}/${transaction.userId}`)}>
                                    {customerSourceLoading ? <p className={'tableSkeletonCell animate-pulse'}/> : getCustomerName(transaction, customerSource?.content)}
                                </TableCell>

                                <TableCell className={'tableCellHover'} onClick={() => navigate(`${routes.orderDetails}/${transaction.orderId}`)}>
                                        {orderSourceLoading ? <p className={'tableSkeletonCell animate-pulse'}/> : getOrderSid(transaction, orderSource?.content)}
                                </TableCell>

                                <TableCell>
                                    {getString([{element: transaction.stripeId, tag: ElementTag.name}])}
                                </TableCell>

                                <TableCell>${transaction.amount}</TableCell>

                                <TableCell>{getString([{
                                    element: transaction.status,
                                    tag: ElementTag.email
                                }])}</TableCell>

                                <TableCell>{stringToFormatDateYear(transaction.createOn.toString())}</TableCell>
                            </TableRow>
                        ))
                        : <OrdersTableSkeleton pageSize={Number(pageSize)} tableRowsCount={6}/>}
                </TableBody>
            </Table>
        </div>

        {!isLoading && <section className={'flex-none flex justify-between'}>
            <p className={'text-sm text-marcoGrayTextColor'}> {currentSelectedRows.length} of {transactionSource?.totalElements} selected</p>
            <Pagination data={transactionSource} pageSize={pageSize} pageIndex={pageIndex} setPageSize={setPageSize}
                        setPageIndex={setPageIndex}/>
        </section>}
    </div>
}