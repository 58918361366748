import OrderCard from "cn/custom/orderCard";
import MemberAndCustomerOrdersSkeleton from "cn/custom/skeletons/memberOrdersSkeleton";
import NoOrders from "cn/custom/skeletons/noOrders";
import {useEffect, useState} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {useNavigate, useParams} from "react-router-dom";
import useSWR from "swr";
import fetcher from "utils/API/fetcher";
import {ApiHost} from "utils/config/config";
import {handleResponseError} from "utils/errorHandler/fetchErrors/handleResponseError";
import {IData} from "utils/types/primary/IData";
import {Order} from "utils/types/primary/Order";

export default function CustomerOrders() {
    const navigate = useNavigate();
    const [pageSize, ] = useState<string>('20');
    const [pageIndex, setPageIndex] = useState<number>(1);

    const [totalRendered, setTotalRendered] = useState<Order[]>([]);
    const [totalElements, setTotalElements] = useState<number>(0);


    const {id} = useParams();

    const {
        data: orderSource,
        error: orderSourceError,
        isLoading: orderSourceLoading
    }
        = useSWR<IData<Order>>(id ? ApiHost + encodeURI(`api/v1/order` +
            `?filter={"user":"${id}"}
        &sort=[createOn, DESC]
        &range=[${(pageIndex - 1) * Number(pageSize)},${(pageIndex - 1) * Number(pageSize) + (Number(pageSize) - 1)}]`) : null,
        fetcher);

    function loadMoreOrders() {
        setPageIndex( prevState => prevState+1)
    }

    useEffect(() => {
        if (orderSourceError) {
            handleResponseError(orderSourceError, () => navigate('/'))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderSourceError]);

    useEffect(() => {
        if (orderSource) {
            setTotalRendered(prevState => [...prevState, ...orderSource.content]);
            setTotalElements(orderSource.totalElements)
        }


    }, [orderSource]);

    if (orderSource && orderSource.content.length === 0) {
        return <NoOrders content={'This customer hasn\'t placed any orders yet'}/>
    }

    if (orderSourceLoading && totalRendered.length === 0) {
        return <MemberAndCustomerOrdersSkeleton/>
    }

    return <div className={'border rounded-lg w-full py-4 px-4 text-sm flex flex-col gap-4 text-marcoDefaultTextColor'}>

            <h2 className={'font-bold'}>Last orders placed</h2>

            <div className={'flex flex-col'}>
                <InfiniteScroll dataLength={totalRendered.length}
                                loader={<p>Loading...</p>}
                                hasMore={totalRendered.length < totalElements}
                                next={loadMoreOrders}>
                {totalRendered.map(el => <OrderCard order={el}/>)}
                </InfiniteScroll>
            </div>
        </div>
}