import {Button} from "cn/components/ui/button";
import {Label} from "cn/components/ui/label";
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "cn/components/ui/select";
import {toast} from "cn/components/ui/use-toast";
import ButtonLoading from "cn/custom/buttonLoading";
import {produce} from "immer";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import updateOrder from "utils/API/updateOrder";
import {OrderFulfillmentStatus} from "utils/enum/orderFulfillmentStatus";
import {handleResponseError} from "utils/errorHandler/fetchErrors/handleResponseError";
import {isInstanceOf} from "utils/errorHandler/fetchErrors/isInstanceOf";
import {ErrorMessage} from "utils/types/primary/errorMessage";
import {Order} from "utils/types/primary/Order";
import useSheet from "utils/zustand/useSheet";

interface Props {
    order: Order
    onMutate: () => Promise<void>
}

export default function ChangeOrderStatus({order, onMutate}: Props) {
    const [isLoading, setLoading] = useState<boolean>(false);
    const {close} = useSheet();
    const [currentOrder, setCurrentOrder] = useState<Order | null>(null);
    const navigate = useNavigate()

    useEffect(() => {
        setCurrentOrder(produce(
            order, draft => {
                if (draft) {
                    draft.invoices = []
                }
            }
        ))
    }, [order]);

    async function onSaveAction() {
        setLoading(true)
        if (currentOrder) {
            const order = await updateOrder(currentOrder);
            if (isInstanceOf<ErrorMessage>(order, 'message')) {
                handleResponseError(order, () => navigate('/'))
            } else {
                await onMutate();
                toast({
                    description: 'Request fetched'
                })
                close()
            }
        }
        setLoading(false)
    }

    function onOrderFulfillmentStatusChange(updatedFulfillmentStatus: string) {
        setCurrentOrder(
            produce(
                currentOrder, draft => {
                    if (draft) {
                        draft.fulfillmentStatus = stringToOrderFulfillmentStatus(updatedFulfillmentStatus)
                    }
                }
            )
        )
    }

    function stringToOrderFulfillmentStatus(orderFulfillmentStatus: string) {
        switch (true) {
            case (orderFulfillmentStatus === 'NOT_ACCEPTED'): {
                return OrderFulfillmentStatus.notAccepted
            }

            case (orderFulfillmentStatus === 'ACCEPTED'): {
                return OrderFulfillmentStatus.accepted
            }

            case (orderFulfillmentStatus === 'DISPATCHED'): {
                return OrderFulfillmentStatus.dispatched
            }

            case (orderFulfillmentStatus === 'AT_PICKUP'): {
                return OrderFulfillmentStatus.atPickup
            }

            case (orderFulfillmentStatus === 'IN_TRANSIT'): {
                return OrderFulfillmentStatus.inTransit
            }

            case (orderFulfillmentStatus === 'COMPLETE_PICKUPT'): {
                return OrderFulfillmentStatus.completePickup
            }

            case (orderFulfillmentStatus === 'START_DELIVERY'): {
                return OrderFulfillmentStatus.startDelivery
            }

            case (orderFulfillmentStatus === 'COMPLETE_DELIVERY'): {
                return OrderFulfillmentStatus.completeDelivery
            }

            default: {
                return OrderFulfillmentStatus.notAccepted
            }
        }
    }

    return <section className={'flex flex-col gap-4 text-sm font-medium h-[calc(100dvh-4.25rem)]'}>
        <div className={'grow'}>

            <div className={'flex flex-col gap-2'}>
                <Label>Order fulfillment status</Label>
                <Select value={currentOrder?.fulfillmentStatus ?? ''}
                        onValueChange={(e) => onOrderFulfillmentStatusChange(e)}>
                    <SelectTrigger>
                        <SelectValue placeholder="Select order fulfillment status"/>
                    </SelectTrigger>
                    <SelectContent>
                        <SelectGroup>
                            {Object.values(OrderFulfillmentStatus).map(el =>
                                <SelectItem
                                    value={el}>{(el[0] + el.slice(1).toLowerCase()).replace('_', ' ')}</SelectItem>)}
                        </SelectGroup>
                    </SelectContent>
                </Select>
            </div>
        </div>

        <div
            className="flex-none items-start justify-between flex w-full gap-4">
            <Button
                className={'w-full'}
                disabled={isLoading}
                type={"reset"}
                variant={"outline"}
                onClick={() => close()}>
                Cancel
            </Button>
            <Button
                className={'w-full'}
                disabled={isLoading}
                variant={'green'}
                onClick={onSaveAction}
            >{isLoading ? <ButtonLoading/> : 'Save'}</Button>
        </div>

    </section>
}