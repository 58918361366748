import {zodResolver} from "@hookform/resolvers/zod";

import {Button} from "cn/components/ui/button";
import {Dialog, DialogContent} from "cn/components/ui/dialog";
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from "cn/components/ui/form";
import {Input} from "cn/components/ui/input";
import {toast} from "cn/components/ui/use-toast";
import {X} from "lucide-react";
import React, {useEffect} from "react";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {handleResponseError} from "utils/errorHandler/fetchErrors/handleResponseError";
import {isInstanceOf} from "utils/errorHandler/fetchErrors/isInstanceOf";
import {ErrorMessage} from "utils/types/primary/errorMessage";
import {Order} from "utils/types/primary/Order";
import useFilesStore from "pages/main/details/orderDetails/sections/media/useFilesStore";
import useProgressBar from "utils/zustand/primary/useProgressBar";
import * as z from "zod";
import addFileFromURLSchema from "./addFileFromURLSchema";
import { getFileFromURL } from "./getFileFromURL";
import { PostBlob } from "./postBlob";

interface Props {
    isOpen: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    mutateFileSource: () => void
    setFilesCount: React.Dispatch<React.SetStateAction<number>>
    setHide: React.Dispatch<React.SetStateAction<boolean>>
    setShowFilesButton: React.Dispatch<React.SetStateAction<string>>
    order: Order | undefined
}

export default function AddFromURLPopUp({
                                            isOpen,
                                            setOpen,
                                            mutateFileSource,
                                            setFilesCount,
                                            setShowFilesButton,
                                            setHide,
    order
                                        }: Props) {

    const {setProgressValue} = useProgressBar();
    const {files} = useFilesStore();

    const navigate = useNavigate()

    useEffect(() => {
        if (isOpen) {
            form.reset()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const form = useForm<z.infer<typeof addFileFromURLSchema>>({
        resolver: zodResolver(addFileFromURLSchema),
        defaultValues: {
            URL: "",
        }
    });

    async function onSubmit(values: z.infer<typeof addFileFromURLSchema>) {
        getFileFromURL(values.URL)
            .then(value => {
                if (isInstanceOf<ErrorMessage>(value, "message")) {
                    handleResponseError(value, () => navigate('/'))
                } else {
                    setProgressValue(50);
                    PostBlob(value, order)
                        .then(value => {
                            if (value === 200) {
                                setProgressValue(100)
                                mutateFileSource();

                                if (files.length + 1 > 7) {
                                    setOpen(false);
                                    setShowFilesButton("Show less files")
                                    setFilesCount(files.length + 1)
                                    setHide(true)
                                } else {
                                    setFilesCount(prevState => prevState + 1)
                                    setOpen(false);
                                }

                                toast({
                                    title: "Fetched",
                                    description: "Request fetched",
                                });
                                setProgressValue(0)
                            } else {
                                toast({
                                    variant: "destructive",
                                    description: "Request failed",
                                })
                            }
                        })
                        .then(value => {
                            if (files.length + 1 > 7) {
                                setOpen(false);
                                setShowFilesButton("Show less files")
                                setFilesCount(files.length + 1)
                                setHide(true)
                            }
                        })
                }
            })
    }

    return <Dialog modal={true} open={isOpen}>
        <DialogContent className="sm:max-w-[425px]">
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <div className="grid gap-4">
                        <section className={"flex justify-between text-zinc-800 text-base font-semibold font-inter"}>
                            <div>Add file from URL</div>
                            <X className={"cursor-pointer"} onClick={() => setOpen(false)}/>
                        </section>

                        <FormField
                            control={form.control}
                            name="URL"
                            render={({field}) => (
                                <FormItem>
                                    <FormLabel
                                        className={"text-black text-sm font-normal font-inter leading-tight"}>
                                        File url
                                    </FormLabel>
                                    <FormControl>
                                        <Input
                                            placeholder={"https://"}
                                            className={"w-full text-black text-sm font-normal"}
                                            {...field}/>
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            )}
                        />

                        <section className={"flex justify-between"}>
                            <Button size={"sm"} type={"reset"} onClick={() => setOpen(false)}>Cancel</Button>
                            <Button size={"sm"} disabled={form.getValues().URL === ""} type={"submit"}>Submit</Button>
                        </section>
                    </div>
                </form>
            </Form>
        </DialogContent>
    </Dialog>
}