import {Button} from "cn/components/ui/button";
import {Checkbox} from "cn/components/ui/checkbox";
import {DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger} from "cn/components/ui/dropdown-menu";
import {Input} from "cn/components/ui/input";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "cn/components/ui/table";
import Pagination from "cn/custom/pagination";
import CategoryCreate from "cn/custom/sheet/sheetContent/category/categoryCreate";
import CategoryEdit from "cn/custom/sheet/sheetContent/category/categoryEdit";
import OrdersTableSkeleton from "cn/custom/skeletons/OrdersTableSkeleton";
import {ArrowDownWideNarrow, ArrowUpNarrowWide} from "lucide-react";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import useSWR from "swr";
import fetcher from "utils/API/fetcher";
import {ApiHost} from "utils/config/config";
import {handleResponseError} from "utils/errorHandler/fetchErrors/handleResponseError";
import getString, {ElementTag} from "utils/stringHandlers/getString";
import {Category} from "utils/types/primary/Category";
import {IData} from "utils/types/primary/IData";
import useCategories from "utils/zustand/tables/useCategories";
import useSheet from "utils/zustand/useSheet";

export default function Categories() {

    const navigate = useNavigate();

    const {
        categoriesList,
        setCategoriesList,
        pageIndex,
        setPageIndex,
        searchBar,
        setSearchBar,
        sort,
        setSort,
        pageSize,
        setPageSize,
        resetPagination,
        isAnySelected,
        checkAllRows,
        currentSelectedRows,
        checkRow,
        isRowChecked
    } = useCategories()

    const {
        data: categorySource,
        mutate: mutateCategorySource,
        error,
        isLoading
    } = useSWR<IData<Category>>(ApiHost + encodeURI(`api/v1/category` +
        `?filter={q:"${searchBar}"}
        &sort=[${sort}]
        &range=[${(pageIndex - 1) * Number(pageSize)},${(pageIndex - 1) * Number(pageSize) + (Number(pageSize) - 1)}]`),
        fetcher);

    const onMutate = async () => {
        await mutateCategorySource()
    }

    useEffect(() => {
        if (error) {
            handleResponseError(error, () => navigate('/'))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    useEffect(() => {
        if (!error && categorySource) {
            setCategoriesList(categorySource.content);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categorySource]);

    const {open} = useSheet();

    return <div className={'flex flex-col gap-4 h-full'}>
        <h1 className={'flex-none text-2xl font-semibold text-marcoDefaultTextColor font-inter'}>Categories</h1>

        <section className={'flex-none text-sm flex justify-between place-items-center'}>
            <div className={'flex grow gap-4 place-items-center'}>
                <Input
                    className={"px-3 py-2 w-[17.25rem] h-8 relative bg-[#ffffff] rounded-lg border border-solid font-subtle font-[number:var(--subtle-font-weight)] text-[#717171] text-[length:var(--subtle-font-size)] tracking-[var(--subtle-letter-spacing)] leading-[var(--subtle-line-height)] whitespace-nowrap [font-style:var(--subtle-font-style)] caret-black"}
                    defaultValue={searchBar}
                    placeholder={`Search category...`}
                    onChange={
                        (event) => {
                            setSearchBar(event.currentTarget.value)
                        }
                    }/>
            </div>
            <Button className={'flex-none'} size={'sm'} onClick={() => {
                open('Create category', <CategoryCreate onMutate={onMutate}/>)
            }}>Create category</Button>
        </section>

        <div className={'flex-1 grow'}>
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableHead className="w-11">
                            <Checkbox checked={isAnySelected()}
                                      onCheckedChange={() => checkAllRows(categoriesList)}/>
                        </TableHead>

                        <TableHead className={'tableCellHover w-96'}>
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <p>Name</p>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent className="w-fit" align={'start'}>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`name,ASC`);
                                                          resetPagination()
                                                      }}><ArrowUpNarrowWide className={'h-4'}/> <p>Ascending</p>
                                    </DropdownMenuItem>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`name,DESC`);
                                                          resetPagination()
                                                      }}><ArrowDownWideNarrow
                                        className={'h-4'}/> <p>Descending</p></DropdownMenuItem>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </TableHead>

                        <TableHead className={'tableCellHover w-96'}>
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <p>Description</p>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent className="w-fit" align={'start'}>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`description,ASC`);
                                                          resetPagination()
                                                      }}><ArrowUpNarrowWide className={'h-4'}/> <p>Ascending</p>
                                    </DropdownMenuItem>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`description,DESC`);
                                                          resetPagination()
                                                      }}><ArrowDownWideNarrow
                                        className={'h-4'}/> <p>Descending</p></DropdownMenuItem>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody className={`${(isLoading || categorySource?.content.length === 0) && 'h-[31.3rem]'}`}>
                    {!isLoading
                        ? categoriesList.map((category) => (
                            <TableRow
                                key={category.id}
                                onClick={() => {
                                    open('Edit category', <CategoryEdit onMutate={onMutate} category={category}/>)
                                }}
                                className={'cursor-pointer'}
                            >
                                <TableCell>
                                    <Checkbox checked={isRowChecked(category)}
                                              onCheckedChange={() => checkRow(category)}
                                              onClick={(e) => {
                                                  e.stopPropagation()
                                              }}
                                    />
                                </TableCell>

                                <TableCell>{getString([{element: category.name, tag: ElementTag.name}])}</TableCell>

                                <TableCell>{category.description}</TableCell>
                            </TableRow>
                        ))
                        : <OrdersTableSkeleton pageSize={Number(pageSize)} tableRowsCount={2}/>}
                </TableBody>
            </Table>
        </div>

        {!isLoading && <section className={'flex-none flex justify-between'}>
            <p className={'text-sm text-marcoGrayTextColor'}> {currentSelectedRows.length} of {categorySource?.totalElements} selected</p>
            <Pagination data={categorySource} pageSize={pageSize} pageIndex={pageIndex} setPageSize={setPageSize}
                        setPageIndex={setPageIndex}/>
        </section>}
    </div>
}