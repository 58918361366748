import {Separator} from "cn/components/ui/separator";
import {useEffect, useState} from "react";
import formatEnumValueToString from "utils/stringHandlers/formatEnumValueToString";
import getNumberStories from "utils/stringHandlers/getAddress/getNumberStories";
import getPropertySize from "utils/stringHandlers/getAddress/getPropertySize";
import getSquareFootage from "utils/stringHandlers/getAddress/getSquareFootage";
import getUnitSize from "utils/stringHandlers/getAddress/getUnitSize";
import getString, {ElementTag} from "utils/stringHandlers/getString";
import {Address} from "utils/types/primary/Address";
import {Order} from "utils/types/primary/Order";
import useSheet from "utils/zustand/useSheet";
import {ReactComponent as Dots} from "../../../../../icons/threeDotsInLine.svg";
import EditDestinationAddress
    from "../../../../../cn/custom/sheet/sheetContent/editOrder/address/editDestinationAddress";

interface Props {
    order: Order | undefined,
    mutateOrder: () => void
}

export default function DestinationAddress({order, mutateOrder}: Props) {
    const [destinationAddress, setDestinationAddress] = useState<Address | null>(null);
    const {open} = useSheet()

    useEffect(() => {
        if (order) {
            const destination = order.addresses?.filter(el => el.direction === 'DESTINATION');
            if (destination && destination[0]) {
                setDestinationAddress(destination[0])
            }
        }
    }, [order]);

    return <div
        key={'order-destination-address'}
        className={'border rounded-lg'}>
        <div className={'flex justify-between p-2 rounded-t-lg place-items-center'}>
            <h3 className={'text-sm font-semibold '}>Destination address</h3>
            <div
                onClick={() => order && open('Edit destination address', <EditDestinationAddress onMutate={mutateOrder}
                                                                                                 order={order}/>)}
                className={'grid place-items-center xDefault'}>
                <Dots/>
            </div>
        </div>

        {destinationAddress && <>
            <Separator/>

            <div className={'flex flex-col gap-1 p-2'}>
                <p>{getString([
                    {element: destinationAddress?.address, tag: ElementTag.address},
                    {element: destinationAddress?.city, tag: ElementTag.address},
                    {element: destinationAddress?.state, tag: ElementTag.address},
                    {element: destinationAddress?.country, tag: ElementTag.address},
                ])}</p>

                <p>{getString([
                    {
                        element: destinationAddress?.propertyType ? formatEnumValueToString(destinationAddress?.propertyType) : '',
                        tag: ElementTag.unit
                    },
                    {
                        element: destinationAddress?.access ? 'access: ' + formatEnumValueToString(destinationAddress?.access).toLowerCase() : '',
                        tag: ElementTag.unit
                    },


                    {
                        element: destinationAddress?.squareFootage ? getSquareFootage(destinationAddress.squareFootage) : '',
                        tag: ElementTag.unit
                    },
                    {
                        element: destinationAddress?.propertySize ? getPropertySize(destinationAddress.propertySize) : '',
                        tag: ElementTag.unit
                    },
                    {
                        element: destinationAddress?.unitSize ? getUnitSize(destinationAddress.unitSize) : '',
                        tag: ElementTag.unit
                    },
                    {
                        element: destinationAddress?.numberStories ? getNumberStories(destinationAddress.numberStories) : '',
                        tag: ElementTag.unit
                    },
                    {
                        element: destinationAddress?.floorNumber ? 'floor: ' + formatEnumValueToString(destinationAddress?.floorNumber).toLowerCase() : '',
                        tag: ElementTag.unit
                    },
                    {
                        element: destinationAddress?.unitNumber ? 'unit: ' + formatEnumValueToString(destinationAddress?.unitNumber).toLowerCase() : '',
                        tag: ElementTag.unit
                    },
                ])}</p>

            </div>
        </>}
    </div>
}