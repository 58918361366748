import {Button} from "cn/components/ui/button";
import {Textarea} from "cn/components/ui/textarea";
import {toast} from "cn/components/ui/use-toast";
import ButtonLoading from "cn/custom/buttonLoading";
import {produce} from "immer";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import updateOrder from "utils/API/updateOrder";
import {handleResponseError} from "utils/errorHandler/fetchErrors/handleResponseError";
import {isInstanceOf} from "utils/errorHandler/fetchErrors/isInstanceOf";
import {ErrorMessage} from "utils/types/primary/errorMessage";
import {Order} from "utils/types/primary/Order";
import useSheet from "utils/zustand/useSheet";

interface Props {
    order: Order
    onMutate: () => void
}

export default function EditNote({order, onMutate}: Props) {
    const navigate = useNavigate();
    const {close} = useSheet();
    const [isLoading, setLoading] = useState<boolean>(false);

    const [value, setValue] = useState<string>('');

    useEffect(() => {
        if (order.notes) {
            setValue(order.notes)
        } else {
            setValue('')
        }
    }, [order]);

    async function onSubmit() {
        setLoading(true);

        const updatedOrder: Order = produce(
            order,
            (draft) => {
                if (draft) {
                    draft.notes = value
                    draft.invoices = []
                    draft.transactions = []
                }
            }
        )

        const res = await updateOrder(updatedOrder)
        if (isInstanceOf<ErrorMessage>(res, 'message')) {
            handleResponseError(res, () => navigate('/'))
        } else {
            onMutate();
            toast({
                description: 'Request fetched'
            })
        }

        setLoading(false);
        close();
    }

    return <section className={'flex flex-col gap-4'}>

        <Textarea
            placeholder="Type your message here"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            className={'h-80'}
            maxLength={200}
        />

        <div
            className="items-start justify-between flex w-full gap-4">
            <Button
                className={'w-full'}
                disabled={isLoading}
                variant={"outline"}
                onClick={() => close()}>
                Cancel
            </Button>

            <Button
                className={'w-full'}
                disabled={isLoading}
                onClick={onSubmit}
                variant={'green'}

            >{isLoading ? <ButtonLoading/> : 'Save'}</Button>
        </div>

    </section>
}