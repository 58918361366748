import {create} from 'zustand'
import {ReactNode} from "react";

interface State {
    isOpen: boolean
    title: string | null
    content: ReactNode | null
}

interface Actions {
    open: (title: string, content: ReactNode) => void
    close: () => void
}

const useSheet = create<State & Actions>(
    (set, get) => ({
        isOpen: false,
        title: null,
        content: null,

        open: (title: string, content: ReactNode) => {
            set(
                (state) => (
                    {
                        isOpen: true,
                        title: title,
                        content: content,
                    }
                )
            )
        },

        close: () => {
            set(
                (state) => (
                    {
                        isOpen: false,
                    }
                )
            )
        },
    })
)

export default useSheet;
