import {Application} from "utils/types/primary/Application";
import {create} from 'zustand'

interface State {
    application: Application | null
}

interface Actions {
    setApplication: (application: Application) => void
    resetApplicationStore: () => void
}

const useApplication = create<State & Actions>(
    (set, get) => ({
        application: null,

        setApplication: (application: Application) => {
            set(
                (state) => (
                    {
                        application: application,
                    }
                )
            )
        },

        resetApplicationStore: () => {
            set(
                (state) => (
                    {
                        application: null,
                    }
                )
            )
        }



    })
)

export default useApplication;