import {Textarea} from "cn/components/ui/textarea";
import {toast} from "cn/components/ui/use-toast";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import editCategory from "utils/API/editCategory";
import {handleResponseError} from "utils/errorHandler/fetchErrors/handleResponseError";
import {isInstanceOf} from "utils/errorHandler/fetchErrors/isInstanceOf";
import {Category} from "utils/types/primary/Category";
import {ErrorMessage} from "utils/types/primary/errorMessage";
import useSheet from "utils/zustand/useSheet";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import * as z from "zod";
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from "cn/components/ui/form";
import {Input} from "cn/components/ui/input";
import {Button} from "cn/components/ui/button";
import ButtonLoading from "cn/custom/buttonLoading";
import categorySchema from "utils/zodSchemas/categorySchema";

interface Props {
    category: Category
    onMutate: () => Promise<void>
}

export default function RateEdit({category, onMutate}: Props) {
    const navigate = useNavigate();
    const {close} = useSheet();
    const [isLoading, setLoading] = useState<boolean>(false);

    const form = useForm<z.infer<typeof categorySchema>>({
        resolver: zodResolver(categorySchema),
        defaultValues: {
            name: "",
            description: "",
            enable: false,
            id: null,
        },
    });

    useEffect(() => {
        form.setValue("id", category.id ?? '');
        form.setValue("name", category.name ?? '');
        form.setValue("description", category.description ?? '');
        form.setValue("enable", category.enable   ?? true);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category]);

    async function onSubmit(values: z.infer<typeof categorySchema>) {
        setLoading(true);

        const value: Category = {
            id: Number(values.id),
            name: values.name,
            enable: values.enable,
            description: values.description
        }

        const res = await editCategory(value)

        if (isInstanceOf<ErrorMessage>(res, 'message')){
            handleResponseError(res, () => navigate('/'))
        } else {
            await onMutate();
            toast({
                description: 'Request fetched'
            })
        }

        setLoading(false);
        close();
    }

    return <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className={"flex flex-col gap-4"}>
            <section className={"flex flex-col gap-4"}>

                <FormField
                    control={form.control}
                    name="name"
                    render={({field}) => (
                        <FormItem>
                            <FormLabel>Title</FormLabel>
                            <FormControl>
                                <Input
                                    {...field}
                                    placeholder={"Title"}
                                    required
                                    type={"text"}
                                    minLength={2}
                                />
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}
                />

                <FormField
                    control={form.control}
                    name="description"
                    render={({field}) => (
                        <FormItem>
                            <FormLabel>Description</FormLabel>
                            <FormControl>
                                <Textarea
                                    {...field}
                                    className={"max-h-[15.6rem]"}
                                    placeholder={"Type your message here"}
                                    required
                                    minLength={2}
                                />
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}
                />
            </section>


            <div
                className="items-start justify-between flex w-full gap-4">
                <Button
                    className={'w-full'}
                    disabled={isLoading}
                    type={"reset"}
                    variant={"outline"}
                    onClick={() => close()}>
                    Cancel
                </Button>
                <Button
                    className={'w-full'}
                    disabled={isLoading}
                    variant={'green'}
                    type={"submit"}>{isLoading ? <ButtonLoading/> : 'Save'}</Button>
            </div>
        </form>
    </Form>
}