import {OrderFulfillmentStatus} from "utils/enum/orderFulfillmentStatus";
import {ReactComponent as GrayFullPip} from "../../../icons/pips/gray/fullPip.svg";
import {ReactComponent as GreenFullPip} from "../../../icons/pips/green/fullPip.svg";
import {ReactComponent as OrangeEmptyPip} from "../../../icons/pips/orange/emptyPip.svg";
import {ReactComponent as OrangeFullPip} from "../../../icons/pips/orange/fullPip.svg";
import {ReactComponent as OrangeHalfPip} from "../../../icons/pips/orange/halfPip.svg";

interface Props {
    status: OrderFulfillmentStatus | null
}

export default function FulfillmentStatusBadge({status}: Props) {
    switch (status) {

        case OrderFulfillmentStatus.notAccepted: {
            return (
                <div
                    className={"w-fit flex gap-[4px]  px-2 py-2 h-[20px] rounded-xl bg-[#FFEA8A] items-center text-neutral-600 text-xs font-semibold font-['Inter'] leading-tight"}>
                    <OrangeEmptyPip/>
                    <span>Not accepted</span>
                </div>
            )
        }

        case OrderFulfillmentStatus.startDelivery: {
            return (
                <div
                    className={"w-fit flex gap-[4px]  px-2 py-2 h-[20px] rounded-xl bg-[#FFEA8A] items-center text-neutral-600 text-xs font-semibold font-['Inter'] leading-tight"}>
                    <OrangeEmptyPip/>
                    <span>Start delivery</span>
                </div>
            )
        }

        case OrderFulfillmentStatus.dispatched: {
            return (
                <div
                    className={"w-fit flex gap-[4px]  px-2 py-2 h-[20px] rounded-xl bg-[#FFEA8A] items-center text-neutral-600 text-xs font-semibold font-['Inter'] leading-tight"}>
                    <OrangeHalfPip/>
                    <span>Dispatched</span>
                </div>
            )
        }

        case OrderFulfillmentStatus.accepted: {
            return (
                <div
                    className={"w-fit flex gap-[4px]  px-2 py-2 h-[20px] rounded-xl bg-[#FFEA8A] items-center text-neutral-600 text-xs font-semibold font-['Inter'] leading-tight"}>
                    <OrangeHalfPip/>
                    <span>Accepted</span>
                </div>
            )
        }

        case OrderFulfillmentStatus.completeDelivery: {
            return (
                <div
                    className={"w-fit flex gap-[4px]  px-2 py-2 h-[20px] rounded-xl bg-[#FFEA8A] items-center text-neutral-600 text-xs font-semibold font-['Inter'] leading-tight"}>
                    <OrangeHalfPip/>
                    <span>Compete delivery</span>
                </div>
            )
        }

        case OrderFulfillmentStatus.atPickup: {
            return (
                <div
                    className={"w-fit flex gap-[4px]  px-2 py-2 h-[20px] rounded-xl bg-[#FFEA8A] items-center text-neutral-600 text-xs font-semibold font-['Inter'] leading-tight"}>
                    <OrangeFullPip/>
                    <span>At pickup</span>
                </div>
            )
        }

        case OrderFulfillmentStatus.completePickup: {
            return (
                <div
                    className={"w-fit flex gap-[4px]  px-2 py-2 h-[20px] rounded-xl bg-emerald-200 items-center text-neutral-600 text-xs font-semibold font-['Inter'] leading-tight"}>
                    <GreenFullPip/>
                    <span>Complete pickup</span>
                </div>
            )
        }

        case OrderFulfillmentStatus.inTransit: {
            return (
                <div
                    className={"w-fit flex gap-[4px]  px-2 py-2 h-[20px] rounded-xl bg-zinc-200 items-center text-neutral-600 text-xs font-semibold font-['Inter'] leading-tight"}>
                    <GrayFullPip/>
                    <span>In transit</span>
                </div>
            )
        }

        default: {
            return null
        }
    }
}