import {toast} from "cn/components/ui/use-toast";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import POSTRate from "utils/API/POSTRate";
import {handleResponseError} from "utils/errorHandler/fetchErrors/handleResponseError";
import {isInstanceOf} from "utils/errorHandler/fetchErrors/isInstanceOf";
import {ErrorMessage} from "utils/types/primary/errorMessage";
import {Rate} from "utils/types/primary/Rate";
import rateSchema from "utils/zodSchemas/rateSchema";
import useSheet from "utils/zustand/useSheet";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import * as z from "zod";
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from "cn/components/ui/form";
import {Input} from "cn/components/ui/input";
import {Button} from "cn/components/ui/button";
import ButtonLoading from "cn/custom/buttonLoading";

interface Props {
    rate: Rate
    onMutate: () => Promise<void>
}

export default function RateEdit({rate, onMutate}: Props) {
    const navigate = useNavigate();
    const {close} = useSheet();
    const [isLoading, setLoading] = useState<boolean>(false);

    const form = useForm<z.infer<typeof rateSchema>>({
        resolver: zodResolver(rateSchema),
        defaultValues: {
            id: "",
            rate: 0.1,
            zipcode: "00000",
            createOn: "",
            county: "",
            state: "",
            homePrice: ""
        }
    });

    useEffect(() => {
        form.setValue("id", rate.id ?? '');
        form.setValue("rate", rate.rate ?? '');
        form.setValue("zipcode", rate.zipcode ?? '');
        form.setValue("createOn", rate.createOn ?? '');
        form.setValue("state", rate.state ?? '');
        form.setValue("county", rate.county ?? '');
        form.setValue("homePrice", rate.homePrice ?? '');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rate]);

    async function onSubmit(values: z.infer<typeof rateSchema>) {
        setLoading(true);

        const res = await POSTRate(values);

        if (isInstanceOf<ErrorMessage>(res, 'message')) {
            handleResponseError(res, () => navigate('/'))
        } else {
            await onMutate();
            toast({
                description: 'Request fetched'
            })
        }

        setLoading(false);
        close();
    }

    return <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className={"flex flex-col gap-[16px]"}>
            <section className={"flex flex-col gap-4"}>
                <FormField
                    control={form.control}
                    name="rate"
                    render={({field}) => (
                        <FormItem>
                            <FormLabel>Rate</FormLabel>
                            <FormControl>
                                <Input
                                    type={"number"}
                                    required
                                    min={0.1}
                                    max={3}
                                    step={0.05}
                                    {...field}/>
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}
                />
            </section>


            <div
                className="items-start justify-between flex w-full gap-4">
                <Button
                    className={'w-full'}
                    disabled={isLoading}
                    type={"reset"}
                    variant={"outline"}
                    onClick={() => close()}>
                    Cancel
                </Button>
                <Button
                    className={'w-full'}
                    disabled={isLoading}
                    variant={'green'}
                    type={"submit"}>{isLoading ? <ButtonLoading/> : 'Save'}</Button>
            </div>
        </form>
    </Form>
}