import {Checkbox} from "cn/components/ui/checkbox";
import {DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger} from "cn/components/ui/dropdown-menu";
import {Input} from "cn/components/ui/input";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "cn/components/ui/table";
import Pagination from "cn/custom/pagination";
import OrdersTableSkeleton from "cn/custom/skeletons/OrdersTableSkeleton";
import {ArrowDownWideNarrow, ArrowUpNarrowWide} from "lucide-react";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import useSWR from "swr";
import fetcher from "utils/API/fetcher";
import {ApiHost} from "utils/config/config";
import routes from "utils/config/routes";
import {handleResponseError} from "utils/errorHandler/fetchErrors/handleResponseError";
import getString, {ElementTag} from "utils/stringHandlers/getString";
import {Customer} from "utils/types/primary/Customer";
import {IData} from "utils/types/primary/IData";
import useCustomers from "utils/zustand/tables/useCustomers";

export default function Customers() {
    const navigate = useNavigate();

    const {
        customerList,
        setCustomerList,
        pageIndex,
        setPageIndex,
        searchBar,
        setSearchBar,
        sort,
        setSort,
        pageSize,
        setPageSize,
        resetPagination,
        isAnySelected,
        checkAllRows,
        currentSelectedRows,
        checkRow,
        isRowChecked
    } = useCustomers()

    const {
        data: customerSource,
        error,
        isLoading
    } = useSWR<IData<Customer>>(ApiHost + encodeURI(`api/v1/customer` +
        `?filter={q:"${searchBar}"}
        &sort=[${sort}]
        &range=[${(pageIndex - 1) * Number(pageSize)},${(pageIndex - 1) * Number(pageSize) + (Number(pageSize) - 1)}]`),
        fetcher);

    useEffect(() => {
        if (error) {
            handleResponseError(error, () => navigate('/'))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    useEffect(() => {
        if (!error && customerSource) {
                setCustomerList(customerSource.content);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerSource]);

    return <div className={'flex flex-col gap-4 h-full'}>
        <h1 className={'flex-none text-2xl font-semibold text-marcoDefaultTextColor font-inter'}>Customers</h1>

        <section className={'flex-none text-sm flex justify-between place-items-center'}>
            <div className={'flex grow gap-4 place-items-center'}>
                <Input
                    className={"px-3 py-2 w-[17.25rem] h-8 relative bg-[#ffffff] rounded-lg border border-solid font-subtle font-[number:var(--subtle-font-weight)] text-[#717171] text-[length:var(--subtle-font-size)] tracking-[var(--subtle-letter-spacing)] leading-[var(--subtle-line-height)] whitespace-nowrap [font-style:var(--subtle-font-style)] caret-black"}
                    defaultValue={searchBar}
                    placeholder={`Search customer...`}
                    onChange={
                        (event) => {
                            setSearchBar(event.currentTarget.value)
                        }
                    }/>
            </div>
        </section>

        <div className={'flex-1 grow'}>
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableHead className="w-11">
                            <Checkbox checked={isAnySelected()}
                                      onCheckedChange={() => checkAllRows(customerList)}/>
                        </TableHead>

                        <TableHead className={'tableCellHover w-96'}>
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <p>Name</p>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent className="w-fit" align={'start'}>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`firstName,ASC`);
                                                          resetPagination()
                                                      }}><ArrowUpNarrowWide className={'h-4'}/> <p>Ascending</p>
                                    </DropdownMenuItem>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`firstName,DESC`);
                                                          resetPagination()
                                                      }}><ArrowDownWideNarrow
                                        className={'h-4'}/> <p>Descending</p></DropdownMenuItem>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </TableHead>

                        <TableHead className={'tableCellHover w-96'}>
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <p>Email</p>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent className="w-fit" align={'start'}>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`email,ASC`);
                                                          resetPagination()
                                                      }}><ArrowUpNarrowWide
                                        className={'h-4'}/> <p>Ascending</p></DropdownMenuItem>
                                    <DropdownMenuItem className={'flex gap-2 place-items-center cursor-pointer'}
                                                      onClick={() => {
                                                          setSort(`email,DESC`);
                                                          resetPagination()
                                                      }}><ArrowDownWideNarrow
                                        className={'h-4'}/> <p>Descending</p></DropdownMenuItem>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </TableHead>

                        <TableHead className={'tableCellHover w-96'}>Orders</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody className={`${(isLoading || customerSource?.content.length === 0) && 'h-[31.3rem]'}`}>
                    {!isLoading
                        ? customerList.map((customer) => (
                            <TableRow
                                key={customer.id}
                                onClick={(e) => navigate(`${routes.customerDetails}/${customer.id}`)}
                                className={'cursor-pointer'}
                            >
                                <TableCell>
                                    <Checkbox checked={isRowChecked(customer)}
                                              onCheckedChange={() => checkRow(customer)}
                                              onClick={(e) => {
                                                  e.stopPropagation()
                                              }}
                                    />
                                </TableCell>

                                <TableCell>{getString([
                                        {element: customer.firstName, tag: ElementTag.name},
                                        {element: customer.lastName, tag: ElementTag.name}
                                    ]
                                )}</TableCell>

                                <TableCell>{getString([{element: customer.email, tag: ElementTag.email}])}</TableCell>

                                <TableCell>{customer.orders.length}</TableCell>

                            </TableRow>
                        ))
                        : <OrdersTableSkeleton pageSize={Number(pageSize)} tableRowsCount={3}/>}
                </TableBody>
            </Table>
        </div>

        {!isLoading && <section className={'flex-none flex justify-between'}>
            <p className={'text-sm text-marcoGrayTextColor'}> {currentSelectedRows.length} of {customerSource?.totalElements} selected</p>
            <Pagination data={customerSource} pageSize={pageSize} pageIndex={pageIndex} setPageSize={setPageSize}
                        setPageIndex={setPageIndex}/>
        </section>}
    </div>
}