import {Button} from "cn/components/ui/button";
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "cn/components/ui/select";
import {toast} from "cn/components/ui/use-toast";
import ButtonLoading from "cn/custom/buttonLoading";
import {produce} from "immer";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import submitCarbon from "utils/API/submitCarbon";
import submitPacking from "utils/API/submitPacking";
import {Packing} from "utils/enum/packing";
import {handleResponseError} from "utils/errorHandler/fetchErrors/handleResponseError";
import {isInstanceOf} from "utils/errorHandler/fetchErrors/isInstanceOf";
import formatEnumValueToString from "utils/stringHandlers/formatEnumValueToString";
import {ErrorMessage} from "utils/types/primary/errorMessage";
import {Order} from "utils/types/primary/Order";
import useSheet from "utils/zustand/useSheet";

interface Props {
    order: Order
    onMutate: () => void
}

export default function EditServices({order, onMutate}: Props) {
    const navigate = useNavigate();
    const {close} = useSheet();
    const [isLoading, setLoading] = useState<boolean>(false);

    const [carbonEnabled, setCarbonEnabled] = useState<string>('');
    const [orderPacking, setOrderPacking] = useState<string>('');


    useEffect(() => {
        if (order.services.CARBON) {
            setCarbonEnabled(order.services.CARBON)
        } else setCarbonEnabled('DISABLED')

        if (order.services.PACKING) {
            setOrderPacking(order.services.PACKING)
        } else setOrderPacking('BASIC')
    }, [order]);

    async function onSubmit() {
        setLoading(true);

        const updatedOrder: Order = produce(
            order,
            (draft) => {
                draft.services.CARBON = carbonEnabled
                draft.services.PACKING = orderPacking
                draft.invoices = []
                draft.transactions = []
            }
        )

        const updatedCarbon = await submitCarbon(updatedOrder);

        if (isInstanceOf<ErrorMessage>(updatedCarbon, 'message')) {
            handleResponseError(updatedCarbon, () => navigate('/'))
        } else {
            onMutate();
            toast({
                description: 'Carbon request fetched'
            })
        }

        const updatedPacking = await submitPacking(updatedOrder);

        if (isInstanceOf<ErrorMessage>(updatedPacking, 'message')) {
            handleResponseError(updatedPacking, () => navigate('/'))
        } else {
            onMutate();
            toast({
                description: 'Packing request fetched'
            })
        }
        setLoading(false);
        close();
    }

    return <section className={'flex flex-col gap-4'}>
        <Select value={orderPacking} onValueChange={(e) => setOrderPacking(e)}>
            <SelectTrigger className="w-full">
                <SelectValue placeholder="Select time"/>
            </SelectTrigger>
            <SelectContent>
                <SelectGroup>
                    <SelectItem
                        value="BASIC">{formatEnumValueToString(Packing.notRequiredPacking).replace('_', " ")}</SelectItem>
                    <SelectItem
                        value="PREMIUM">{formatEnumValueToString(Packing.partialPacking).replace('_', " ")}</SelectItem>
                    <SelectItem
                        value="FULL_SERVICE">{formatEnumValueToString(Packing.fullPacking).replace('_', " ")}</SelectItem>
                </SelectGroup>
            </SelectContent>
        </Select>

        <Select value={carbonEnabled} onValueChange={(e) => setCarbonEnabled(e)}>
            <SelectTrigger className="w-full">
                <SelectValue placeholder="Select time"/>
            </SelectTrigger>
            <SelectContent>
                <SelectGroup>
                    <SelectItem key={'enabled'} value={'ENABLED'}>Enabled</SelectItem>
                    <SelectItem key={'enabled'} value={'DISABLED'}>Disabled</SelectItem>
                </SelectGroup>
            </SelectContent>
        </Select>


        <div
            className="items-start justify-between flex w-full gap-4">
            <Button
                className={'w-full'}
                disabled={isLoading}
                variant={"outline"}
                onClick={() => close()}>
                Cancel
            </Button>

            <Button
                className={'w-full'}
                disabled={isLoading}
                variant={'green'}
                onClick={onSubmit}
            >{isLoading ? <ButtonLoading/> : 'Save'}</Button>
        </div>
    </section>


}